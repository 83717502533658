<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <div class="product">
            <img :src="t.pictureUrl" class="img" />
            <div class="info">
              <div class="title">
                <div class="type">{{t.projectName}}</div>
                <div class="name">{{t.companyName}}</div>
              </div>
              <div class="desc">
                <div class="desc-item">
                  <label class="info-text">融资类型</label>
                  <label class="info-value">{{financeTypeDic[t.financeType]}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">所属领域</label>
                  <label class="info-value">{{fieldDic[t.field]}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-text">融资金额</label>
                  <label class="info-value">{{t.financeAmount}}万</label>
                </div>
              </div>
            </div>
            <div class="more" @click="handleDetailClick(t.id)">查看详情</div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'projectList',
  components: {
    TopFilter
  },
  data() {
    return {
      fieldDic: { 1: "生物医药", 2: "先进制造", 3: "现代服务业", 4: "现代农业" },
      financeTypeDic: { 1: "股权融资", 2: "债权融资", 3: "信贷融资", 4: "其他" },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
      typeList: [
        {
          name: '融资类型',
          code: 'financeType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '股权融资',
              val: 1,
            },
            {
              name: '债权融资',
              val: 2,
            },
            {
              name: '信贷融资',
              val: 3,
            },
            {
              name: '其他',
              val: 4,
            }
          ]
        },
        {
          name: '所属领域',
          code: 'field',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '生物医药',
              val: 1,
            },
            {
              name: '先进制造',
              val: 2,
            },
            {
              name: '现代服务',
              val: 3,
            },
            {
              name: '现代农业',
              val: 4,
            }
          ]
        },
      ],
      sortConfig: [
        {
          name: '发布时间',
          code: 1,
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.projectList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/projectDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  //margin-top: 10px;
  padding: 20px;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    .product {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 150px;
      border: 1px solid #ececec;
      align-items: center;
      .img {
        width: 61px;
        height: 62px;
      }
      .info {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          .type {
            width: 60%;
            font-weight: 700;
            padding: 10px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name {
            color: #0046c0;
            font-size: 14px;
            font-weight: 200;
            padding: 10px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .desc {
          display: flex;
          flex-direction: row;
          padding: 10px 20px;
          justify-content: space-around;
          .desc-item {
            display: flex;
            flex-direction: column;
            width: 20%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .info-text {
              font-size: 14px;
              font-weight: 200;
            }
            .info-value {
              padding-top: 10px;
              font-size: 14px;
              color: black;
            }
          }
        }
      }
      .more {
        display: inline-block;
        background-color: #0046c0;
        color: #fff;
        padding: 8px 15px;
        border-radius: 6px;
        margin: 10px 20px 20px;
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
